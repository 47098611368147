<template>
  <base-section id="financial-report" 
  class="grey lighten-4">
    <base-section-heading
      color="grey lighten-2"
      title="Laporan Keuangan"
    />

    <v-container>
      <v-tabs
      background-color="secondary"
      dark
    >
      <v-tab href="#tahunan">Publish Koran</v-tab>
      <v-tab href="#triwulan">Triwulan</v-tab>
      <v-tab href="#bulanan">Bulanan</v-tab>
      <v-tab href="#keberlanjutan">Keberlanjutan</v-tab>
      <v-tab-item
       value="tahunan">
        <v-card flat>
          <v-card-text>
            <v-list-item 
                    v-for="card in report_pk"
                    :key="card.web_report_name"
                    @click="Open(card.web_report_path_url)">
              <v-list-item-content>
                <v-list-item-title><v-icon>mdi-file-pdf-box</v-icon>{{ card.web_report_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
       value="triwulan">
        <v-card flat>
          <v-card-text> <v-list-item 
                    v-for="card in report_tw"
                    :key="card.web_report_name"
                    @click="Open(card.web_report_path_url)">
              <v-list-item-content>
                <v-list-item-title><v-icon>mdi-file-pdf-box</v-icon>{{ card.web_report_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
       value="bulanan">
        <v-card flat>
          <v-card-text> <v-list-item 
                    v-for="card in report_bl"
                    :key="card.web_report_name"
                    @click="Open(card.web_report_path_url)">
              <v-list-item-content>
                <v-list-item-title><v-icon>mdi-file-pdf-box</v-icon>{{ card.web_report_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
       value="keberlanjutan">
        <v-card flat>
          <v-card-text> <v-list-item 
                    v-for="card in report_kb"
                    :key="card.web_report_name"
                    @click="Open(card.web_report_path_url)">
              <v-list-item-content>
                <v-list-item-title><v-icon>mdi-file-pdf-box</v-icon>{{ card.web_report_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item></v-card-text>
        </v-card>
      </v-tab-item>
  </v-tabs>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFinancialReport',
    data: () => ({
      report_pk: [],
      report_tw: [],
      report_bl: [],
      report_kb: [],
    }),
    created() {
      this.Report('PK')
      this.Report('TW')
      this.Report('BL')
      this.Report('KB')
    },
    methods: {
      Report(category) {
        let formdata = {
              web_report_category: category,
              order_by: 'web_report_year',
              order_type: 'DESC',
        }
        let param      = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWebReportList'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            let data = response.data
            if (data.length > 0) {
                if (data[0].feedback === 'Y') {
                if (category === 'PK') {
                  this.report_pk = data
                } else if (category === 'TW') {
                  this.report_tw = data
                } else if (category === 'BL') {
                  this.report_bl = data
                } else if (category === 'KB') {
                  this.report_kb = data
                }
              } else {
                this.snackbar.value = true
                this.snackbar.text  = data[0].feedback
              }
            } else {
              if (category === 'PK') {
                this.report_pk = []
              } else if (category === 'TW') {
                this.report_tw = []
              } else if (category === 'BL') {
                this.report_bl = []
              } else if (category === 'KB') {
                this.report_kb = []
              }
            }
        })
        .catch(e => {
            this.snackbar.value = true
            this.snackbar.text  = e
        })
      },
      Open(url) {
        window.open(url,'_blank')
      }
    }
  }
</script>
